import Lightbox from 'bs5-lightbox'

const load_lightbox = () => {
    document.querySelectorAll('.lightbox-toggle').forEach((el) => el.addEventListener('click', (e) => {
        e.preventDefault();
        const lightbox = new Lightbox(el);
        lightbox.show();
    }));
}


document.addEventListener("DOMContentLoaded", function () {
    load_lightbox();
})
